
import Spoiler from '~/components/elements/Spoiler/Spoiler';

export default {
  name: 'TreeMenuSpoiler',
  components: {Spoiler},
  props: {
    title: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    isIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      spoilerState: false,
      spoilerOptions: {
        iconClass: '',
        icon: {
          // wrapperClass: 'spoiler-icon',
          wrapperClass: 'spoiler-icon',
          openOnly: true,
        },
      },
    }
  },
  methods: {
    spoilerStateHandler(state) {
      this.spoilerState = state
    },
  },
}
