
import TreeMenu from '~/components/elements/TreeMenu';
import TreeMenuSpoiler from '~/components/elements/TreeMenu/TreeMenuSpoiler';
export default {
  name: 'TreeMenuItem',
  components: {
    TreeMenuSpoiler,
    TreeMenu,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isIcon() {
      return this.item?.sublist?.length > 0
    },
  },
}
