
export default {
  name: 'Spoiler',
  props: {
    options: {
      type: Object,
      default() {
        return {}
      },
    },
    opened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      spoiler: {},
      defaultOptions: {
        duration: 500,
        iconClass: 'spoiler__plus',

        icon: {
          wrapperClass: '',
          openOnly: false,
        },
        // breakPoint: {
        //   value: 991.98,
        //   type: 'min'
        // },
      },
      spoilerState: false,

      $spoilerToggle: null,
      $spoilerBody: null,

      spoilerInited: false,
      spoilerActive: false,
      spoilerDisabled: false,

      matchMedia: null,

      openTask: null,
      closeTask: null,
    }
  },
  computed: {
    getSpoilerIconClass() {
      return this.spoilerDisabled ? '' : this.spoiler.iconClass
    },
    checkIconWrapper() {
      return this.spoiler.icon.wrapperClass && !this.spoilerDisabled
    },
  },
  watch: {
    spoilerDisabled(value) {
      if (value) {
        this.open()
        return
      }

      this.init()
      this.opened ? this.open() : this.close()
    },
  },
  created() {
    Object.assign(this.spoiler, this.defaultOptions, this.options)
  },
  mounted() {
    this.$spoilerToggle = this.$refs.spoilerTitle
    this.$spoilerBody = this.$refs.spoilerTitle.nextElementSibling

    if (!this.spoiler.breakPoint) {
      this.init()
    } else {
      const breakPoint = `screen and (${this.spoiler.breakPoint.type}-width: ${this.spoiler.breakPoint.value}px)`
      this.matchMedia = window.matchMedia(breakPoint);

      if(this.matchMedia) {
        if (this.matchMedia.addEventListener) {
          this.matchMedia.addEventListener('change', this.init);
        } else {
          this.matchMedia.addListener(this.init);
        }
      }

      this.init();
    }

    this.$spoilerToggle && this.$spoilerToggle.addEventListener('click', this.clickToggleHandler, false)

    this.opened && this.open()
  },

  beforeDestroy() {
    this.spoilerInited = false
    this.spoilerDisabled = false

    if(this.$spoilerToggle) {
      this.$spoilerToggle.removeEventListener('click', this.clickToggleHandler, false)
    }

    if(this.matchMedia) {
      if (this.matchMedia.removeEventListener) {
        this.matchMedia.removeEventListener('change', this.init);
      } else {
        this.matchMedia.removeListener(this.init);
      }
    }

    this.openTask && clearTimeout(this.openTask)
    this.closeTask && clearTimeout(this.closeTask)
  },
  methods: {
    clickToggleHandler(e) {
      if (this.spoilerDisabled || this.$spoilerBody.classList.contains('_slide')) return;

      if (this.spoiler.icon.openOnly && !e.target.closest(`.${this.spoiler.icon.wrapperClass}`)) {
        return;
      }

      e.preventDefault()
      this.toggle(!this.spoilerState)
    },
    init() {
      if (!this.matchMedia || this.matchMedia.matches) {
        this.spoilerInited = true
        this.spoilerDisabled = false

        return
      }

      this.spoilerDisabled = true
    },
    disable() {
      this.spoilerInited = false
      this.spoilerDisabled = true
    },
    open() {
      const parent = this.getParent(this.$parent)

      if (parent?.onlyOneActive && !this.spoilerDisabled) {
        parent.activeElements.forEach(el => {
          el.close()
        })
      }

      this.spoilerActive = true
      this.$spoilerBody.hidden = false;

      const height = this.getHeight(this.$spoilerBody);

      this.clearStyles(this.$spoilerBody);
      // eslint-disable-next-line no-unused-expressions
      this.$spoilerBody.offsetHeight;

      this.setSpoilerTransition(this.$spoilerBody, height)
      this.removeIndentProps(this.$spoilerBody)

      this.$spoilerBody.classList.add('_slide');

      this.openTask = setTimeout(() => {
        this.spoilerState = true

        this.removeTransitionProps(this.$spoilerBody)

        this.$spoilerBody.classList.remove('_slide');
        this.$emit('state', this.spoilerState)
      }, this.spoiler.duration)

      this.$emit('statePre', true)
    },
    close() {
      if (this.spoilerDisabled) return;

      this.spoilerActive = false

      const height = this.getHeight(this.$spoilerBody);
      this.setSpoilerTransition(this.$spoilerBody, height)

      // eslint-disable-next-line no-unused-expressions
      this.$spoilerBody.offsetHeight;
      this.clearStyles(this.$spoilerBody);

      this.$spoilerBody.classList.add('_slide');

      this.closeTask = setTimeout(() => {
        this.spoilerState = false
        this.$spoilerBody.hidden = true;

        this.removeIndentProps(this.$spoilerBody)
        this.removeTransitionProps(this.$spoilerBody)

        this.$spoilerBody.classList.remove('_slide');
        this.$emit('state', this.spoilerState)
      }, this.spoiler.duration);

      this.$emit('statePre', false)
    },
    toggle(value) {
      return value ? this.open() : this.close();
    },
    clearStyles(el) {
      el.style.overflow = 'hidden';
      el.style.height = 0;
      el.style.paddingTop = 0;
      el.style.paddingBottom = 0;
      el.style.marginTop = 0;
      el.style.marginBottom = 0;
    },
    removeIndentProps(el) {
      el.style.removeProperty('padding-top');
      el.style.removeProperty('padding-bottom');
      el.style.removeProperty('margin-top');
      el.style.removeProperty('margin-bottom');
    },
    removeTransitionProps(el) {
      el.style.removeProperty('height');
      el.style.removeProperty('overflow');
      el.style.removeProperty('transition-duration');
      el.style.removeProperty('transition-property');
    },
    setSpoilerTransition(el, height) {
      el.style.transitionProperty = 'height, margin, padding';
      el.style.transitionDuration = this.spoiler.duration + 'ms';
      el.style.height = height + 'px';
    },
    getHeight(el) {
      const height = parseInt(document.defaultView.getComputedStyle(el, '').getPropertyValue('height'));
      const margin = parseInt(document.defaultView.getComputedStyle(el, '').getPropertyValue('margin-top')) +
        parseInt(document.defaultView.getComputedStyle(el, '').getPropertyValue('margin-bottom'));

      return height + margin;
    },
    getParent(parent) {
      let curParent = parent

      if (!curParent) return null

      for (let i = 0; i < 10; i++) {
        if (curParent?.$el?.classList?.contains('spoiler-wrapper')) {
          return curParent
        }

        curParent = curParent?.$parent
      }

      return curParent
    },
  },
}
